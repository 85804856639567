// Home Page Video
let overlay = document.getElementById("js-video-overlay");
let loop = document.getElementById("js-video-loop");
let video = document.getElementById("js-video-main");

overlay.addEventListener("click", function(){
    start();
    ga('send', 'event', 'Home Video', 'Play'); // Google Analytics
});

// Hides overlay & plays video from beginning
function start() {
    video.currentTime = 0;
    video.muted = false;
    video.play();
    overlay.classList.add('u-hidden');
    loop.classList.add('u-hidden');
}

